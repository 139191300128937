import { Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addUser } from '../../redux/userStore';
import { useEffect } from 'react';
import { setSurveys } from '../../redux/surveyStore';
import { setQuestion } from '../../redux/questionStore';
import '../../Pages/css/file1.css';
import { useGetCurrentPacientQuery } from '../../redux/API/pacients';

function PrintAdminOutlet() {
  const dispatch = useDispatch();
  let { id, idDoc } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useGetCurrentPacientQuery({ id });

  useEffect(() => {
    const user = data;
    dispatch(addUser(data));

    let health = user?.questionHealth;
    let survey = user?.questionSurvey;

    if (health) {
      if (idDoc && parseInt(idDoc)) {
        if (parseInt(idDoc) <= health.length) {
          dispatch(setQuestion(health[parseInt(idDoc) - 1]?.health));
          dispatch(setSurveys(survey[parseInt(idDoc) - 1]?.survey));
        } else {
          navigate(
            `${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}/${health.length}`,
          );
        }
      } else {
        dispatch(setQuestion(health[health.length - 1]?.health));
        dispatch(setSurveys(survey[survey.length - 1]?.survey));
      }
    }
  }, [data]);

  return (
    <div className="print-wrapper">
      <div className="nk-block-between g-3 pt-5">
        <div className="nk-block-head-content">
          <div
            onClick={() => window.history.back()}
            className="btn btn-outline-light bg-white d-none d-sm-inline-flex">
            <em className="icon ni ni-arrow-left"></em>
            <span>Повернутись назад</span>
          </div>
          <div
            onClick={() => window.history.back()}
            className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none">
            <em className="icon ni ni-arrow-left"></em>
          </div>
        </div>

        <div className="nk-block-head-content">
          <div
            onClick={() => window.print()}
            className="btn btn-outline-light bg-white d-none d-sm-inline-flex">
            <em className="icon ni ni-printer-fill"></em>
            <span>Друк/Зберегти</span>
          </div>
          <div
            onClick={() => window.print()}
            className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none">
            <em className="icon ni ni-printer-fill"></em>
          </div>
        </div>
      </div>
      <div className="agree-document">
        <Outlet />
      </div>
    </div>
  );
}

export default PrintAdminOutlet;
