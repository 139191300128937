import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { BurgerMenu } from '../BurgerMenu';
import { useGetAdminAccessQuery } from '../../redux';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { clearQuestion } from '../../redux/questionStore';
import { clearSurvey } from '../../redux/surveyStore';
import { completeRegistration } from '../../redux/userStore';

function AdminOutlet() {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      sessionStorage.getItem('question') &&
      !['health', 'survey', 'agreement'].some((value) => location.pathname.includes(value))
    ) {
      dispatch(completeRegistration());
      dispatch(clearQuestion());
      dispatch(clearSurvey());
    }
  }, [location]);

  return (
    <>
      <ToastContainer />
      <Outlet />
      <BurgerMenu />
    </>
  );
}

export default AdminOutlet;
