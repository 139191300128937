import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

function NotFound({title = 'Frankolab'}) {

    const [timer, setTimer] = useState(5)
    const navigate = useNavigate()

    useEffect(()=>{
        setInterval(()=>{
            if (timer === 0){
                setTimer(0)
            } else {
                setTimer(timer-1)
            }

        }, 1000)

        if (timer === 0){
            navigate('/')
        }


    },[timer])

    useEffect(()=>{
        document.title = `${title} | FrankoLab`
    },[title])

    return (
        <div className="nk-app-root">
            <div className="nk-main ">
                <div className="nk-wrap nk-wrap-nosidebar">
                    <div className="nk-content ">
                        <div className="nk-block nk-block-middle wide-md mx-auto">
                            <div className="nk-block-content nk-error-ld text-center">
                                <div className="nk-error-gfx">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 300">
                                        <path
                                            d="M299.73,256.79q-.06-2.87-.21-5.83-2-37.08-17-55.8t-41.29-17.3q-26.34,1.42-39.17,21.66t-10.84,57.27Z"
                                            fill="none"
                                            stroke="#6576ff"
                                            strokeMiterlimit={10}
                                            strokeWidth={2}
                                        />
                                        <path
                                            d="M218.09,256.79H273c0-1.43-.11-2.89-.19-4.38Q271.2,223.55,264,211t-21.56-11.82q-14.32.78-20.14,14.19-6,13.74-4.19,43.46Z"
                                            fill="#eff1ff"
                                        />
                                        <path
                                            d="M218.09,256.79H273c0-1.43-.11-2.89-.19-4.38Q271.2,223.55,264,211t-21.56-11.82q-14.32.78-20.14,14.19-6,13.74-4.19,43.46Z"
                                            fill="none"
                                            stroke="#6576ff"
                                            strokeMiterlimit={10}
                                            strokeWidth={2}
                                        />
                                        <path
                                            d="M444.57,256.79a1,1,0,0,1-1,1H60.87a1,1,0,0,1-.95-1h0a1,1,0,0,1,.95-1H443.61a1,1,0,0,1,1,1Z"
                                            fill="#6576ff"
                                        />
                                        <path
                                            d="M57.94,100.53a6.46,6.46,0,0,0,6.46-5.44,12.54,12.54,0,0,0,.12-1.75,12.71,12.71,0,0,0-12.71-12.7c-.28,0-.57,0-.85,0A13.5,13.5,0,0,0,24,81.81c0,.33,0,.66,0,1a13.36,13.36,0,0,0-7.2-1.7A13.55,13.55,0,0,0,4,93.61s0,.1,0,.16a6.43,6.43,0,0,0,6.45,6.76Z"
                                            fill="#e1e6ff"
                                            opacity="0.5"
                                            style={{ isolation: "isolate" }}
                                        />
                                        <path
                                            d="M487,141.87c11.54,0,12.3-17.38.78-18-.38,0-.75,0-1.13,0a19.38,19.38,0,0,0-5.94.93,19.94,19.94,0,0,0-39.43.63,18.38,18.38,0,0,0-7.94-1.79,18.06,18.06,0,0,0-4.44.54c-10.27,2.55-8.27,17.77,2.31,17.77Z"
                                            fill="#e1e6ff"
                                            opacity="0.5"
                                            style={{ isolation: "isolate" }}
                                        />
                                        <path
                                            d="M377.41,57.19c12.76,0,13.59-19.21.86-19.94-.41,0-.83,0-1.25,0a21.5,21.5,0,0,0-6.56,1,22,22,0,0,0-43.58.7,20.32,20.32,0,0,0-13.67-1.39c-11.35,2.81-9.15,19.64,2.55,19.64Z"
                                            fill="#e1e6ff"
                                            opacity="0.5"
                                            style={{ isolation: "isolate" }}
                                        />
                                        <g opacity="0.5">
                                            <polygon
                                                points="66.81 24.06 64.03 27.93 61.25 31.79 59.3 27.45 57.34 23.11 62.08 23.59 66.81 24.06"
                                                fill="#dae0f7"
                                            />
                                            <polygon
                                                points="362.97 98.71 359.57 104.24 356.18 109.77 353.08 104.07 349.99 98.36 356.48 98.53 362.97 98.71"
                                                fill="#dae0f7"
                                            />
                                            <polygon
                                                points="468.75 169.32 472.35 171.75 475.94 174.19 472.03 176.08 468.13 177.97 468.44 173.65 468.75 169.32"
                                                fill="#dae0f7"
                                            />
                                            <polygon
                                                points="126.81 72.6 130.44 71.43 134.07 70.26 133.27 73.99 132.47 77.72 129.64 75.16 126.81 72.6"
                                                fill="#dae0f7"
                                            />
                                            <polygon
                                                points="56.82 153.96 61.99 154.97 67.17 154.97 64.62 158.88 62.07 163.38 59.45 158.92 56.82 153.96"
                                                fill="#dae0f7"
                                            />
                                            <polygon
                                                points="277.04 21.66 281.67 23.97 286.3 26.27 281.99 29.13 277.68 31.99 277.36 26.82 277.04 21.66"
                                                fill="#dae0f7"
                                            />
                                            <polygon
                                                points="448.92 34.22 451.37 35.44 453.83 36.66 451.54 38.18 449.26 39.7 449.09 36.96 448.92 34.22"
                                                fill="#dae0f7"
                                            />
                                        </g>
                                        <path
                                            d="M223.69,159.57,285.2,141.5a2.16,2.16,0,0,0,1.47-2.69l-25.1-85.49a2.19,2.19,0,0,0-2.7-1.48L216.76,64.21a2.19,2.19,0,0,0-1.28,1L201.56,89.54a2.13,2.13,0,0,0-.2,1.69L221,158.09A2.17,2.17,0,0,0,223.69,159.57Z"
                                            fill="#fff"
                                        />
                                        <path
                                            d="M223.69,159.57,285.2,141.5a2.16,2.16,0,0,0,1.47-2.69l-25.1-85.49a2.19,2.19,0,0,0-2.7-1.48L216.76,64.21a2.19,2.19,0,0,0-1.28,1L201.56,89.54a2.13,2.13,0,0,0-.2,1.69L221,158.09A2.17,2.17,0,0,0,223.69,159.57Z"
                                            fill="none"
                                            stroke="#6576ff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <path
                                            d="M216.46,65.54,221.6,83A2.33,2.33,0,0,1,220,85.91l-18.28,5.45"
                                            fill="#fff"
                                            stroke="#6576ff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <rect
                                            x="226.7"
                                            y="130.16"
                                            width="4.65"
                                            height="16.29"
                                            rx={2}
                                            ry={2}
                                            transform="matrix(0.96, -0.28, 0.28, 0.96, -29.69, 70.13)"
                                            fill="#e3e7fe"
                                        />
                                        <rect
                                            x="245.21"
                                            y="129.47"
                                            width="4.65"
                                            height="11.64"
                                            rx={2}
                                            ry={2}
                                            transform="translate(-28.09 75.23) rotate(-16.37)"
                                            fill="#e3e7fe"
                                        />
                                        <rect
                                            x="253.49"
                                            y="122.29"
                                            width="4.65"
                                            height="16.29"
                                            rx={2}
                                            ry={2}
                                            transform="translate(-26.39 77.36) rotate(-16.37)"
                                            fill="#e3e7fe"
                                        />
                                        <rect
                                            x="234.97"
                                            y="122.97"
                                            width="4.65"
                                            height="20.94"
                                            rx={2}
                                            ry={2}
                                            transform="translate(-27.99 72.27) rotate(-16.37)"
                                            fill="#e3e7fe"
                                        />
                                        <line
                                            x1="215.49"
                                            y1="100.47"
                                            x2="256.79"
                                            y2="88.34"
                                            fill="none"
                                            stroke="#c4cefe"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <line
                                            x1="217.45"
                                            y1="107.16"
                                            x2="258.76"
                                            y2="95.03"
                                            fill="none"
                                            stroke="#c4cefe"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <line
                                            x1="219.42"
                                            y1="113.86"
                                            x2="260.73"
                                            y2="101.73"
                                            fill="none"
                                            stroke="#c4cefe"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <path
                                            d="M307.59,136.49l1.54-3.26a1.17,1.17,0,0,0-.66-1.59l-20.59-7.39-.9,1.63L306,137A1.16,1.16,0,0,0,307.59,136.49Z"
                                            fill="#6576ff"
                                        />
                                        <circle cx="272.43" cy="119.33" r="16.68" fill="#e3e7fe" />
                                        <circle cx="272.43" cy="119.33" r="13.2" fill="#fff" />
                                        <circle
                                            cx="272.43"
                                            cy="119.33"
                                            r="16.68"
                                            fill="none"
                                            stroke="#6576ff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <line
                                            x1="273.16"
                                            y1="112.63"
                                            x2="273.06"
                                            y2="121.71"
                                            fill="none"
                                            stroke="#ef837a"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <line
                                            x1="272.35"
                                            y1="125.73"
                                            x2="272.35"
                                            y2="125.73"
                                            fill="none"
                                            stroke="#ef837a"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <circle
                                            cx="272.66"
                                            cy="125.23"
                                            r="0.5"
                                            fill="none"
                                            stroke="#ef837a"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                        />
                                        <path
                                            d="M419.72,256.79c1.1,0,2-.19,1.92-.41s.8-.59,1.87-.81L436,253.06a2,2,0,0,0,1.57-2.36l-5.3-26.37a2,2,0,0,0-2.36-1.56l-12.48,2.5a2,2,0,0,1-2.36-1.56L399,143.48a2,2,0,0,0-2.35-1.57l-38.37,7.71a4.13,4.13,0,0,0-2.69,2.26l-37.88,96.76a8,8,0,0,0-.33,3.82l.47,2.37a2.56,2.56,0,0,0,2.4,2Zm-49.24-68c.37-1,.86-1,1.07.08l8.39,41.73a2,2,0,0,1-1.57,2.35l-23.84,4.79c-1.08.22-1.66-.45-1.28-1.48Z"
                                            fill="#6576ff"
                                        />
                                        <path
                                            d="M167.34,256.79a2.45,2.45,0,0,0,2.33-2L172,241.15a2,2,0,0,0-1.64-2.3l-12.57-2.1a2,2,0,0,1-1.64-2.3l13.45-80.72a2,2,0,0,0-1.64-2.3L129.31,145a4.11,4.11,0,0,0-3.31,1.15l-69.81,77a7.94,7.94,0,0,0-1.67,3.45l-3.61,21.66a2,2,0,0,0,1.64,2.31L88,256.46a29.25,29.25,0,0,0,4,.33ZM126.77,186c.72-.83,1.16-.63,1,.46l-7,42a2,2,0,0,1-2.3,1.64l-24-4c-1.09-.19-1.39-1-.67-1.85Z"
                                            fill="#6576ff"
                                        />
                                        <rect
                                            x="52.39"
                                            y="287.03"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="111.87"
                                            y="285.02"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="156.22"
                                            y="278.97"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="190.5"
                                            y="293.08"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="262.07"
                                            y="287.03"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="346.75"
                                            y="288.04"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="81.62"
                                            y="269.9"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="228.81"
                                            y="275.94"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="381.03"
                                            y="275.94"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="306.43"
                                            y="275.94"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <rect
                                            x="407.24"
                                            y="285.02"
                                            width="21.17"
                                            height="1.01"
                                            rx="0.5"
                                            ry="0.5"
                                            fill="#c4cefe"
                                        />
                                        <path
                                            d="M51.48,256.79a1,1,0,0,1-.88,1H28.18a1,1,0,0,1-.89-1h0a1,1,0,0,1,.89-1H50.6a1,1,0,0,1,.88,1Z"
                                            fill="#6576ff"
                                        />
                                        <path
                                            d="M476.07,256.79a1,1,0,0,1-.89,1H452.77a1,1,0,0,1-.89-1h0a1,1,0,0,1,.89-1h22.41a1,1,0,0,1,.89,1Z"
                                            fill="#6576ff"
                                        />
                                        <path
                                            d="M177,24.93c6.38,0,7-9.61.61-10.13-.29,0-.59,0-.89,0a10.81,10.81,0,0,0-3.34.52,11.21,11.21,0,0,0-22.17.35,10.37,10.37,0,0,0-4.45-1,10.21,10.21,0,0,0-2.61.34c-5.69,1.48-4.48,10,1.41,10Z"
                                            fill="#e1e6ff"
                                            opacity="0.5"
                                            style={{ isolation: "isolate" }}
                                        />
                                    </svg>

                                </div>
                                <div className="wide-xs mx-auto">
                                    <h3 className="nk-error-title">Упс!</h3>
                                    <p className="nk-error-text">
                                        Вибачте за незручності. Схоже, ви намагаєтеся отримати доступ до сторінки, яка або була видалена, або ніколи не існувала.<br /><br />
                                        Ви будете автоматично перенаправлені на головну через:
                                        {timer} сек
                                    </p>
                                    <Link
                                        to="/" className="btn btn-lg btn-primary mt-2">
                                        Повернутися на головну
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NotFound;
