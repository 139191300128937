import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import userReducer from './userStore';
import questionReducer from './questionStore';
import surveyReducer from './surveyStore';
import siteStore from './siteStore';
import { emptyApi } from './API/generalAPI';

const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401 || action.payload.status === 403) {
      sessionStorage.setItem('lastURL', window.location.pathname);
      window.location.href = '/login';
    }
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    survey: surveyReducer,
    question: questionReducer,
    user: userReducer,
    site: siteStore,
    [emptyApi.reducerPath]: emptyApi.reducer,
  },
  middleware: (getDefaultMiddlware) =>
    getDefaultMiddlware().concat(emptyApi.middleware, rtkQueryErrorLogger),
});
