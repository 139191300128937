import { emptyApi } from './generalAPI';

const loginApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    loginAdmin: build.mutation({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
    }),
    getRole: build.query({
      query: () => `auth/role`,
    }),
  }),
});

export const { useLoginAdminMutation, useGetRoleQuery } = loginApi;
