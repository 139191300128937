import {useEffect} from "react";
import HealthStatusPage from "./HealthStatusPage";

function HealthPagePrint() {

    useEffect(()=>{
        window.print()
    },)

    return (
        <>
            <HealthStatusPage />
        </>
    );
}

export default HealthPagePrint;
