import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { useLoginAdminMutation } from '../../redux/API/login';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { setRole } from '../../redux/siteStore';

function Login() {
  const { register, handleSubmit } = useForm();
  const nav = useNavigate();
  const [passVisible, setPassVisible] = useState(true);
  const [req, { isSuccess, error, data }] = useLoginAdminMutation();
  const [token] = useCookies('cookie');
  const dispatch = useDispatch();

  const sendForm = (body) => {
    toast.promise(req(body), {
      pending: 'Обробка',
      success: 'Успішна авторизація 👌',
      error: error?.data?.msg,
    });
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  useEffect(() => {
    if (token.token) {
      nav(sessionStorage.getItem('lastURL') ?? '/admin/users');
    }
  }, [token]);

  useEffect(() => {
    if (isSuccess) {
      const page = sessionStorage.getItem('lastURL') ?? '/admin/users';
      sessionStorage.removeItem('lastURL');
      dispatch(setRole(data));
      nav(page);
    }
  }, [isSuccess]);

  return (
    <>
      <ToastContainer />
      <div className="nk-main ">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-split nk-split-page nk-split-lg">
              <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">Авторизація</h5>
                      <div className="nk-block-des">
                        <p>Отримайте доступ до панелі</p>
                      </div>
                    </div>
                  </div>
                  <form
                    className="form-validate is-alter"
                    autoComplete="off"
                    onSubmit={handleSubmit(sendForm)}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="email-address">
                          Логін
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control form-control-lg"
                          required=""
                          id="email-address"
                          {...register('username')}
                          placeholder="Введіть логін"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Пароль
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <a className="form-icon form-icon-right passcode-switch lg">
                          <em
                            className="passcode-icon icon-show icon ni ni-eye"
                            onClick={() => setPassVisible(!passVisible)}></em>
                          <em
                            className="passcode-icon icon-hide icon ni ni-eye-off"
                            onClick={() => setPassVisible(!passVisible)}></em>
                        </a>
                        <input
                          autoComplete="password"
                          type={passVisible ? 'password' : 'text'}
                          className="form-control form-control-lg"
                          required=""
                          id="password"
                          {...register('password')}
                          placeholder="Введіть пароль"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <button className="btn btn-lg btn-primary btn-block">Вхід</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
