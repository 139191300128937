import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { completeRegistration } from '../../redux/userStore';

import '../../Assets/css/dashlite.css';
import '../../Assets/css/theme.css';
import '../../Assets/css/libs/themify-icons.css';
import '../../Assets/css/libs/bootstrap-icons.css';
import '../../Assets/css/libs/fontawesome-icons.css';
import { clearSurvey } from '../../redux/surveyStore';
import { clearQuestion } from '../../redux/questionStore';
import Header from '../Header';
import { BurgerMenu } from '../BurgerMenu';

function CompleteOutlet() {
  const { complete } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    if (complete) {
      dispatch(completeRegistration());
      dispatch(clearQuestion());
      dispatch(clearSurvey());
    } else {
      nav('/');
    }
  }, [complete]);

  return (
    <div className="nk-app-root">
      <div className="nk-main ">
        <div className="nk-wrap ">
          <Header />
          <div className="nk-content ">
            <div className="container wide-xl">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-content-wrap">
                    <Outlet />
                  </div>
                  <div className="nk-footer">
                    <div className="container wide-xl">
                      <div className="nk-footer-wrap g-2">
                        <div className="nk-footer-copyright"> © 2023</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BurgerMenu />
    </div>
  );
}

export default CompleteOutlet;
