import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useGetNotifyQuery, useReadNotifyMutation } from '../redux/API/notification';

const NotifyItem = ({ id, text, date, read, patient }) => {
  const [readNotify] = useReadNotifyMutation();

  const setReadNotify = async () => {
    await readNotify({ id });
  };

  return (
    <Link
      to={`/admin/user/${patient}`}
      className="nk-notification-item dropdown-inner"
      onClick={setReadNotify}>
      {!read && (
        <div className="nk-notification-icon">
          <em className="icon ni ni-bulb text-warning fs-22px"></em>
        </div>
      )}
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="nk-notification-time">{moment(new Date(date)).calendar()}</div>
      </div>
    </Link>
  );
};

export const NotifyAdmin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { data = [], isSuccess } = useGetNotifyQuery();

  const handleOutsideClick = (e) => {
    if (isOpen && !e.target.closest('#notify-admin')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <li className={`dropdown notification-dropdown ${isOpen && 'show'}`} id="notify-admin">
      <div className="dropdown-toggle nk-quick-nav-icon" data-bs-toggle="dropdown">
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`icon-status2 ${data && 'icon-status-info2'}`}>
          <em className="icon ni ni-bell" />
        </div>
      </div>
      <div
        className={`dropdown-menu dropdown-menu-xl dropdown-menu-end dropdown-menu-s1 ${isOpen &&
          'show show-notify'}`}>
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Сповіщення</span>
          <a href="#">Позначити все як прочитане</a>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            {isSuccess &&
              data.map((e) => {
                return (
                  <NotifyItem
                    id={e._id}
                    text={e.text}
                    date={e.date}
                    read={e.read}
                    patient={e.patient}
                    key={e._id}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </li>
  );
};
