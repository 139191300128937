import { createSlice } from '@reduxjs/toolkit';

const questionSlice = createSlice({
  name: 'site',
  initialState: {
    modal: { name: null, isVisible: false, data: null },
    menu: true,
    isAuth: false,
    role: null,
    redirect: {
      status: false,
      to: null,
    },
  },
  reducers: {
    handlerModal(state, { payload }) {
      state.modal = {
        name: payload?.name || state.modal.name,
        data: payload?.data || state.modal.data,
        isVisible: payload?.isVisible || !state.modal.isVisible,
      };
    },
    handlerMenu(state, { payload }) {
      state.menu = payload || !state.menu;
    },
    handlerIsAuth(state, { payload }) {
      state.isAuth = payload || !state.menu;
    },

    setRole(state, { payload }) {
      state.role = payload || null;
    },
  },
});

export const { handlerModal, handlerMenu, handlerIsAuth, setRole } = questionSlice.actions;

export default questionSlice.reducer;
