import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/uk';

// OUTLET //
import App from './Components/Outlet/App';
import StageOutlet from './Components/Outlet/StageOutlet';
import PrintOutlet from './Components/Outlet/PrintOutlet';
import AdminOutlet from './Components/Outlet/AdminOutlet';
import PrintAdminOutlet from './Components/Outlet/PrintAdminOutlet';
import AdminHomeOutlet from './Components/Outlet/AdminHomeOutlet';
import CompleteOutlet from './Components/Outlet/CompleteOutlet';

// PAGES //
import Pacients from './Pages/Admin/Pacients';
import Login from './Pages/Auth/Login';
import NotFound from './Pages/Error/404';
import CompleteStage from './Pages/Registration/CompleteStage';
import SurveyPagePrint from './Pages/Document/SurveyPrintPage';
import HealthPagePrint from './Pages/Document/HealthPrintPage';
import SurveyDocumentPage from './Pages/Document/SurveyDocumentPage';
import HealthStatusPage from './Pages/Document/HealthStatusPage';
import Stage1 from './Pages/Registration/Stage1';
import Stage3 from './Pages/Registration/Stage3';
import Stage2 from './Pages/Registration/Stage2';
import Stage4 from './Pages/Registration/Stage4';
import Stage5 from './Pages/Registration/Stage5';
import UserProfile from './Pages/Admin/UserProfile';
import AgreementPage from './Pages/Document/AgreementPage';
import AgreementPrintPage from './Pages/Document/AgreementPrintPage';
import Aligners from './Pages/Admin/Aligner';

moment.localeData('uk');

const checkAccessRoute = (id) => {
  if (sessionStorage.getItem('actual_step') && sessionStorage.getItem('actual_step') < id) {
    return redirect(`/reg/${sessionStorage.getItem('actual_step')}`);
  }
  return null;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound />,

    children: [
      {
        path: '',
        loader: () => redirect('/reg'),
      },

      {
        path: 'reg',
        element: <StageOutlet />,
        children: [
          {
            path: '',
            loader: () => {
              if (sessionStorage.getItem('actual_step')) {
                return redirect(`/reg/${sessionStorage.getItem('actual_step')}`);
              }
              return redirect(`/reg/1`);
            },
          },

          {
            path: '1',
            element: <Stage1 title="Інформована згода" />,
          },

          {
            path: '2',
            element: <Stage2 title="Загальна інформація" />,
            loader: () => checkAccessRoute(2),
          },
          {
            path: '3',
            element: <Stage3 title="Анкета про стан здоров’я" />,
            loader: () => checkAccessRoute(3),
          },
          {
            path: '4',
            element: <Stage4 title="Aнкета опитувальник для пацієнта" />,
            loader: () => checkAccessRoute(4),
          },
          {
            path: '5',
            element: <Stage5 title="Підтвердження анкети" />,
            loader: () => checkAccessRoute(5),
          },

          {
            path: '*',
            loader: () => {
              if (sessionStorage.getItem('actual_step')) {
                return redirect(`/reg/${sessionStorage.getItem('actual_step')}`);
              }
              return redirect(`/reg/1`);
            },
          },
        ],
      },
    ],
  },

  {
    path: 'complete',
    element: <CompleteOutlet />,

    children: [
      {
        path: '',
        element: <CompleteStage title="Дякуємо за заповнення анкет" />,
      },
    ],
  },

  {
    path: 'agreement',
    element: <PrintOutlet />,
    loader: () => {
      document.title = 'Інформована згода на попередній огляд та консультацію | Frankolab';
      return null;
    },

    children: [
      {
        path: '',
        element: <AgreementPage />,
      },

      {
        path: 'print',
        element: <AgreementPrintPage />,
      },
    ],
  },

  {
    path: 'survey',
    element: <PrintOutlet />,
    loader: () => {
      document.title = 'Aнкета опитувальник для пацієнта | Frankolab';
      return null;
    },

    children: [
      {
        path: '',
        element: <SurveyDocumentPage />,
      },

      {
        path: 'print',
        element: <SurveyPagePrint />,
      },
    ],
  },

  {
    path: 'health',
    element: <PrintOutlet />,

    loader: () => {
      document.title = 'Анкета про стан здоров’я | Frankolab';
      return null;
    },

    children: [
      {
        path: '',
        element: <HealthStatusPage />,
      },

      {
        path: 'print',
        element: <HealthPagePrint />,
      },
    ],
  },

  {
    path: 'admin',
    element: <AdminOutlet />,

    children: [
      {
        path: '',
        element: <AdminHomeOutlet />,
        children: [
          {
            path: 'users',
            element: <Pacients />,
          },
          {
            path: 'aligner',
            children: [
              {
                path: '',
                element: <Aligners />,
              },
              {
                path: ':id',
                element: <UserProfile />,
              },
            ],
          },
          {
            path: 'user',
            children: [
              {
                path: '',
                loader: () => redirect(`/admin/users`),
              },
              {
                path: ':id',
                element: <UserProfile />,
              },
            ],
          },
        ],
      },
      {
        path: 'user/:id',
        element: <PrintAdminOutlet />,
        children: [
          {
            path: 'agreement',
            children: [
              {
                path: '',
                element: <AgreementPage />,
              },
              {
                path: ':idDoc',
                element: <AgreementPage />,
              },
            ],
          },

          {
            path: 'health',
            children: [
              {
                path: '',
                element: <HealthStatusPage />,
              },
              {
                path: ':idDoc',
                element: <HealthStatusPage />,
              },
            ],
          },
          {
            path: 'survey',
            children: [
              {
                path: '',
                element: <SurveyDocumentPage />,
              },
              {
                path: ':idDoc',
                element: <SurveyDocumentPage />,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: 'login',
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
