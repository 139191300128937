import { Link, NavLink, useMatches } from 'react-router-dom';
import Logo from '../Assets/images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { NotifyAdmin } from './NotifyAdmin';
import { setMobileMenu } from '../redux/userStore';
import { useGetRoleQuery } from '../redux/API/login';
import { useEffect, useState } from 'react';
import { subMenuClinic } from '../Helper/helper';

export const MenuItem = ({ title, url, levelMatch }) => {
  const matches = useMatches();
  const [active, setActive] = useState(false);

  useEffect(() => {
    url?.includes(matches[levelMatch]?.pathname) ? setActive(true) : setActive(false);
  }, [matches]);

  return (
    <li className={active ? 'nk-menu-item active current-page' : 'nk-menu-item'}>
      <NavLink to={url} className="nk-menu-link">
        <span className="nk-menu-text">{title}</span>
      </NavLink>
    </li>
  );
};

const SubMenu = ({ title, subMenu }) => {
  return (
    <li className="nk-menu-item has-sub">
      <a className="nk-menu-link nk-menu-toggle">
        <span className="nk-menu-text">{title}</span>
      </a>
      <ul className="nk-menu-sub">
        {subMenu?.map((e) => (
          <MenuItem title={e.title} url={e.url} levelMatch={e.level} />
        ))}
      </ul>
    </li>
  );
};

function Header() {
  const dispatch = useDispatch();
  const { data } = useGetRoleQuery();

  return (
    <>
      <div className="nk-header nk-header-fixed is-light">
        <div className="container-lg wide-xl">
          <div className="nk-header-wrap">
            <div className="nk-header-brand">
              <Link to="/" className="logo-link">
                <img className="logo-img" src={Logo} alt="logo" />
              </Link>
            </div>

            <ul className="nk-menu nk-menu-main d-none d-lg-flex">
              <MenuItem title="Заповнити анкету" url="/reg/1" levelMatch={1} />
              {data?.role != 'admin' && (
                <SubMenu title="FrankoLab Clinic" subMenu={subMenuClinic} />
              )}
              {data?.role === 'admin' && (
                <>
                  <MenuItem title="Пацієнти" url="/admin/users" levelMatch={2} />
                  <MenuItem title="Нагадування" url="/admin/aligner" levelMatch={2} />
                </>
              )}
            </ul>

            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                {data?.role === 'admin' && <NotifyAdmin />}
                <li className="d-lg-none" onClick={() => dispatch(setMobileMenu(true))}>
                  <a className="toggle nk-quick-nav-icon me-n1">
                    <em className="icon ni ni-menu"></em>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
