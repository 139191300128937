import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDate, getFullName } from '../../../Helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import { handlerModal } from '../../../redux/siteStore';
import { PopupAddNewPacient } from '../Popups';
import { useGetAlignersNotifyQuery } from '../../../redux/API/aligner';
import moment from 'moment';

function Search({ data, setFilteredList }) {
  const [searchInput, setSearchInput] = useState('');
  const [param] = useSearchParams();

  useEffect(() => {
    param.get('search') && setSearchInput(param.get('search'));
  }, [param]);

  useEffect(() => {
    if (searchInput) {
      setFilteredList(
        data?.filter((e) => {
          return searchInput
            .split(' ')
            .every(
              (val) =>
                e.owner._id.toLowerCase().includes(val.toLowerCase()) ||
                e.owner.firstname.toLowerCase().includes(val.toLowerCase()) ||
                e.owner.lastname.toLowerCase().includes(val.toLowerCase()) ||
                e.owner.telegram.toLowerCase().includes(val.toLowerCase()),
            );
        }),
      );
    } else {
      setFilteredList(data);
    }
  }, [data, searchInput]);

  return (
    <div className="form-group">
      <div className="form-control-wrap">
        <div className="form-icon form-icon-left">
          <em className="icon ni ni-user" />
        </div>
        <input
          value={searchInput}
          type="search"
          className="form-control"
          placeholder="Пошук пацієнта"
          onChange={(e) => setSearchInput(e.target.value.replace(/ +/g, ' '))}
          onBlur={() => setSearchInput(searchInput.trim())}
        />
      </div>
    </div>
  );
}

function Aligners() {
  const [filteredList, setFilteredList] = useState([]);
  const { data } = useGetAlignersNotifyQuery();
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.site);

  return (
    <>
      <div className="container wide-xl">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-content-wrap">
              <div className="nk-block nk-block-lg">
                <div className="nk-block-head">
                  <div className="nk-block-between">
                    <div className="nk-block-area align-items-center g-3">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">Список сповіщень - Елайнери</h3>
                        <div className="nk-block-des text-soft">
                          <ul className="list-inline">
                            <li>
                              Всього: <span className="text-base">{data?.length}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="nk-block-head-content d-flex" style={{ gap: 20 }}>
                      <a
                        className="btn btn-primary"
                        onClick={() =>
                          dispatch(
                            handlerModal({
                              name: 'CREATE-NEW-USER',
                            }),
                          )
                        }>
                        <em className="icon ni ni-plus" />
                        <span>Добавити Пацієнта</span>
                      </a>
                    </div>
                  </div>
                </div>

                <span className="preview-title-lg overline-title">Пошук</span>
                <Search data={data} setFilteredList={setFilteredList} />

                <div className="nk-block">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {filteredList &&
                        filteredList.map((e, index) => (
                          <div className="support-queue-item" key={e._id}>
                            <div className="support-queue-link">
                              <div className="support-queue-body" key={e._id}>
                                <div className="support-queue-context">
                                  <h5 className="support-queue-title title">
                                    <Link to={`/admin/user/${e.owner._id}`}>
                                      Нагадування №{index + 1} - {e.owner.firstname}{' '}
                                      {e.owner.lastname}{' '}
                                      <span className="badge rounded-pill badge-dim bg-gray">
                                        {e.owner.gender}
                                      </span>
                                    </Link>
                                  </h5>
                                  <div className="user-full-info-wrapper">
                                    {e.aligner.map((data, index) => {
                                      return data.notify ? (
                                        <div className="support-queue-desc">
                                          <span className="badge rounded-pill bg-success">
                                            {data.name}: {moment(data.date).format('DD/MM/YYYY')} -
                                            Відправлено
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="support-queue-desc">
                                          <span className="badge rounded-pill bg-outline-gray">
                                            {data.name}: {moment(data.date).format('DD/MM/YYYY')}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.isVisible && modal?.name === 'CREATE-NEW-USER' && <PopupAddNewPacient />}
    </>
  );
}

export default Aligners;
