import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDate, getFullName } from '../../Helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import { handlerModal } from '../../redux/siteStore';
import { PopupAddNewPacient } from './Popups';
import { useDeletePacientMutation, useGetPacientsQuery } from '../../redux/API/pacients';
import { toast } from 'react-toastify';

function Pacients() {
  const [filteredList, setFilteredList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const { data, refetch } = useGetPacientsQuery();
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.site);
  const [req] = useDeletePacientMutation();

  const confirmModal = (id) => {
    const res = window.confirm(`Підтверджуєте видалення?`);
    toast.promise(res && req({ id }), {
      pending: 'Обробка',
      success: 'Витрати видалено 👌',
      error: 'Помилка видалення',
    });
  };

  useEffect(() => {
    if (searchInput) {
      setFilteredList(
        data.filter((e) => {
          return searchInput.split(' ').every((val) =>
            getFullName(e)
              .toLowerCase()
              .includes(val.toLowerCase()),
          );
        }),
      );
    } else {
      setFilteredList(data);
    }
  }, [data, searchInput]);

  return (
    <>
      <div className="container wide-xl">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-content-wrap">
              <div className="nk-block nk-block-lg">
                <div className="nk-block-head">
                  <div className="nk-block-between">
                    <div className="nk-block-area align-items-center g-3">
                      <div className="nk-block-head-content">
                        <div
                          onClick={() => window.history.back()}
                          className="btn btn-icon btn-outline-light bg-white d-inline-flex">
                          <em className="icon ni ni-arrow-left"></em>
                        </div>
                      </div>

                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">Список пацієнтів</h3>
                        <div className="nk-block-des text-soft">
                          <ul className="list-inline">
                            <li>
                              Всього: <span className="text-base">{data?.length}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="nk-block-head-content d-flex" style={{ gap: 20 }}>
                      <a
                        className="btn btn-primary"
                        onClick={() =>
                          dispatch(
                            handlerModal({
                              name: 'CREATE-NEW-USER',
                            }),
                          )
                        }>
                        <em className="icon ni ni-plus" />
                        <span>Добавити Пацієнта</span>
                      </a>
                      <a className="btn btn-soft" onClick={() => refetch()}>
                        <em className="icon ni ni-reload-alt" />
                      </a>
                    </div>
                  </div>
                </div>

                <span className="preview-title-lg overline-title">Пошук</span>
                <div className="form-group">
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-left">
                      <em className="icon ni ni-user" />
                    </div>
                    <input
                      value={searchInput}
                      type="search"
                      className="form-control"
                      placeholder="Пошук пацієнта"
                      onChange={(e) => setSearchInput(e.target.value.replace(/ +/g, ' '))}
                      onBlur={() => setSearchInput(searchInput.trim())}
                    />
                  </div>
                </div>

                <div className="nk-block">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {filteredList &&
                        filteredList.map((e) => (
                          <div className="support-queue-item" key={e._id}>
                            <div className="support-queue-link">
                              <div className="support-queue-media">
                                <em className="icon icon-circle ni ni-user-circle bg-purple"></em>
                              </div>

                              <div className="support-queue-body" key={e._id}>
                                <div className="support-queue-context">
                                  <h5 className="support-queue-title title">
                                    <Link to={`/admin/user/${e._id}`}>{getFullName(e)}</Link>
                                  </h5>
                                  <div className="user-full-info-wrapper">
                                    <div className="support-queue-desc">
                                      <span className="badge rounded-pill badge-dim bg-gray">
                                        {e.gender}
                                      </span>
                                    </div>
                                    {e.birthday && (
                                      <div className="support-queue-desc">
                                        <span className="badge rounded-pill badge-dim bg-gray">
                                          Дата народження: {getDate(e.birthday).getDateString()}
                                        </span>
                                      </div>
                                    )}
                                    {e.phone && (
                                      <div className="support-queue-desc">
                                        <span className="badge rounded-pill badge-dim bg-gray">
                                          Телефон: <a href={`tel:${e.phone}`}> {e.phone}</a>
                                        </span>
                                      </div>
                                    )}
                                    {e.email && (
                                      <div className="support-queue-desc">
                                        <span className="badge rounded-pill badge-dim bg-gray">
                                          Email: <a href={`tel:${e.email}`}> {e.email}</a>
                                        </span>
                                      </div>
                                    )}
                                    {e.address && (
                                      <div className="support-queue-desc">
                                        <span className="badge rounded-pill bg-outline-gray">
                                          Адреса: {e.address}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="support-queue-update pacient-queue-update">
                                  <Link to={`/admin/user/${e._id}`} className="fs-20px">
                                    <em className="icon ni ni-user-fill-c text-soft"></em>
                                  </Link>
                                  {e.aligner?.length > 0 && (
                                    <Link
                                      to={`/admin/aligner/?search=${e._id}`}
                                      className="fs-20px">
                                      <em className="icon ni ni-bulb-fill text-soft"></em>
                                    </Link>
                                  )}
                                  {e.questionHealth.length > 0 && (
                                    <>
                                      <Link
                                        to={`/admin/user/${e._id}/agreement`}
                                        className="fs-20px">
                                        <em className="icon ni ni-file-fill text-soft"></em>
                                      </Link>
                                      <Link to={`/admin/user/${e._id}/health`} className="fs-20px">
                                        <em className="icon ni ni-heart-fill text-soft"></em>
                                      </Link>
                                      <Link to={`/admin/user/${e._id}/survey`} className="fs-20px">
                                        <em className="icon ni ni-list-thumb-fill text-soft"></em>
                                      </Link>
                                    </>
                                  )}
                                  {e.questionHealth.length === 0 ? (
                                    e.aligner?.length === 0 && (
                                      <>
                                        <a
                                          className="fs-20px pointer"
                                          onClick={() => confirmModal(e._id)}>
                                          <em className="icon ni ni-trash-fill text-soft"></em>
                                        </a>
                                      </>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.isVisible && modal?.name === 'CREATE-NEW-USER' && <PopupAddNewPacient />}
    </>
  );
}

export default Pacients;
