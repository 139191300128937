import { emptyApi } from './generalAPI';

const formPacientApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    checkUserExist: build.mutation({
      query: (body) => ({
        url: 'form/check-user',
        method: 'POST',
        body,
      }),
    }),

    addNewRequest: build.mutation({
      query: (body) => ({
        url: `form/create`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useAddNewRequestMutation, useCheckUserExistMutation } = formPacientApi;
