import { emptyApi } from './generalAPI';

const alignerApi = emptyApi
  .enhanceEndpoints({ addTagTypes: ['Aligner', 'Aligners'] })
  .injectEndpoints({
    endpoints: (build) => ({
      addNewAlignerNotify: build.mutation({
        query: (body) => ({
          url: `aligner/order`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['USER', 'USERS', 'Aligners'],
      }),
      getAlignersNotify: build.query({
        query: (id) => `aligner/order`,
        providesTags: ['Aligners'],
      }),
    }),
  });

export const { useGetAlignersNotifyQuery, useAddNewAlignerNotifyMutation } = alignerApi;
