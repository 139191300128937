import { emptyApi } from './generalAPI';

const pacientApi = emptyApi.enhanceEndpoints({ addTagTypes: ['USER', 'USERS'] }).injectEndpoints({
  endpoints: (build) => ({
    getPacients: build.query({
      query: () => 'admin/users',
      providesTags: () => ['USERS'],
    }),

    getCurrentPacient: build.query({
      query: (data) => `admin/users?id=${data?.id}`,
      providesTags: () => ['USER'],
    }),

    addNewPacient: build.mutation({
      query: (body) => ({
        url: `admin/pacient`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['USERS'],
    }),

    deletePacient: build.mutation({
      query: (body) => ({
        url: `admin/pacient`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['USERS'],
    }),

    deleteTelegram: build.mutation({
      query: (body) => ({
        url: `admin/telegram`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['USER', 'USERS'],
    }),
  }),
});

export const {
  useAddNewPacientMutation,
  useGetCurrentPacientQuery,
  useGetPacientsQuery,
  useDeleteTelegramMutation,
  useDeletePacientMutation,
} = pacientApi;
