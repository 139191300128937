import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const cookies = Object.fromEntries(document.cookie.split('; ').map((cookie) => cookie.split('=')));

export const emptyApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    headers: {
      'X-CSRF-Token': cookies['CSRF-Token'],
    },
  }),
  keepUnusedDataFor: 120,
  refetchOnFocus: true,
  endpoints: () => ({}),
});
