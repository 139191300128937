import {Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {configActualStep, configUserFromStorage} from "../../redux/userStore";
import '../../Assets/css/dashlite.css'
import '../../Assets/css/theme.css'
import '../../Assets/css/libs/themify-icons.css'
import '../../Assets/css/libs/bootstrap-icons.css'
import '../../Assets/css/libs/fontawesome-icons.css'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer , toast } from 'react-toastify';

import {configSurveysFromStorage} from "../../redux/surveyStore";
import {configQuestionFromStorage} from "../../redux/questionStore";
import Header from "../Header";
import {BurgerMenu} from "../BurgerMenu";



function App() {

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.user)
  const { survey } = useSelector(state => state.survey)
  const { question } = useSelector(state => state.question)
  const navigate = useNavigate()


  useEffect(()=>{
    if(sessionStorage.getItem('actual_step')){
      dispatch(configActualStep())
    } else {
      sessionStorage.setItem('actual_step', 1);
      navigate('/reg/1')
    }
  },[sessionStorage.getItem('actual_step'), dispatch])

  
  useEffect(()=>{

    if (!survey && sessionStorage.getItem('survey')){
      dispatch(configSurveysFromStorage())
    }

  },[survey, dispatch])

  useEffect(()=>{

    if (user.firstname === '' && sessionStorage.getItem('user')){
      dispatch(configUserFromStorage())
    }

  },[user, dispatch])

  useEffect(()=>{

    if (!question && sessionStorage.getItem('question')){
      dispatch(configQuestionFromStorage())
    }

  },[question, dispatch])

  return (
      <div className="nk-app-root">
        <ToastContainer/>
        <div className="nk-main ">
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content ">
              <div className="container wide-xl">
                <div className="nk-content-inner">
                  <div className="nk-content-body">
                    <div className="nk-content-wrap">
                      <Outlet />
                    </div>
                    <div className="nk-footer pt-5">
                      <div className="container wide-xl">
                        <div className="nk-footer-wrap g-2">
                          <div className="nk-footer-copyright"> © 2023</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BurgerMenu />
      </div>

  );
}

export default App;
