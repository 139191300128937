import { NavLink, useLocation, useMatches } from 'react-router-dom';
import { stepsMenu, subMenuClinic } from '../Helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setMobileMenu } from '../redux/userStore';
import { useGetRoleQuery } from '../redux/API/login';

import { MenuItem, SubMenu } from './Header';

const BurgerMenuOutlet = ({ title, icon, isMenuOpen, url, children }) => {
  const [active, setActive] = useState(false);
  const matches = useMatches();

  useEffect(() => {
    !isMenuOpen && setActive(false);
  }, [isMenuOpen]);

  useEffect(() => {
    url?.includes(matches[1]?.pathname) && setActive(true);
  }, [matches]);

  return (
    <li className={active ? 'nk-menu-item has-sub active' : 'nk-menu-item has-sub'}>
      <a
        className="nk-menu-link nk-menu-toggle"
        onClick={() => {
          setActive(!active);
        }}>
        <span className="nk-menu-icon">
          <em className={`icon ni ${icon}`}></em>
        </span>
        <span className="nk-menu-text">{title}</span>
      </a>

      <ul className={active ? 'nk-menu-sub active' : 'nk-menu-sub'}>{children}</ul>
    </li>
  );
};

const MenuSteps = ({ title, icon, isMenuOpen, steps, url, step }) => {
  return (
    <BurgerMenuOutlet title={title} icon={icon} isMenuOpen={isMenuOpen} url={url}>
      {stepsMenu?.map((e, index) => (
        <li className="nk-menu-item" key={index}>
          <NavLink to={e.link} className="nk-menu-link">
            <span className={steps[e.stepName] ? `nk-menu-text text-success` : `nk-menu-text`}>
              {step === index + 1 && <em className="icon ni ni-chevron-right me-1"></em>} {e.title}
            </span>
          </NavLink>
        </li>
      ))}
    </BurgerMenuOutlet>
  );
};

const MenuWithSub = ({ title, subMenu, icon, isMenuOpen }) => {
  return (
    <BurgerMenuOutlet title={title} icon={icon} isMenuOpen={isMenuOpen}>
      {subMenu?.map((e) => (
        <MenuItem title={e.title} url={e.url} levelMatch={e.level} />
      ))}
    </BurgerMenuOutlet>
  );
};

export const BurgerMenu = () => {
  const { steps, mobileMenu, step } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useGetRoleQuery();
  const handleOutsideClick = (e) => {
    if (mobileMenu && !e.target.closest('#menu-mob')) {
      dispatch(setMobileMenu(false));
    }
  };

  useEffect(() => {
    mobileMenu && dispatch(setMobileMenu(false));
  }, [location]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [mobileMenu]);

  return (
    <div className={mobileMenu ? `menu-mobile` : `menu-mobile hidden`}>
      <div
        className={mobileMenu ? `menu-mobile-wrapper show` : `menu-mobile-wrapper hide`}
        id="menu-mob">
        <ul className="nk-menu">
          {data?.role === 'admin' && (
            <>
              <li className="nk-menu-item">
                <NavLink to="/admin/users" className="nk-menu-link nk-menu-toggle">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-users"></em>
                  </span>
                  <span className="nk-menu-text">Пацієнти</span>
                </NavLink>
              </li>
            </>
          )}

          <MenuSteps
            title={'Заповнити анкету'}
            icon={'ni-info'}
            step={step}
            steps={steps}
            url="/reg"
            isMenuOpen={mobileMenu}
          />
          <MenuWithSub
            title={'FrankoLab Clinic'}
            icon={'ni-building'}
            subMenu={subMenuClinic}
            isMenuOpen={mobileMenu}
          />
        </ul>
      </div>
    </div>
  );
};
