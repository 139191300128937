import { useLocation, useParams, Link } from 'react-router-dom';
import { getDate, getFullName } from '../../Helper/helper';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { handlerModal } from '../../redux/siteStore';
import { PopupAddNotify, PopupConnectTelegram, PopupViewAligner } from './Popups';
import { useDeleteTelegramMutation, useGetCurrentPacientQuery } from '../../redux/API/pacients';
import { toast } from 'react-toastify';

function UserProfile() {
  const location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.site);
  const { data: user, refetch } = useGetCurrentPacientQuery({ id });
  const [req, { data }] = useDeleteTelegramMutation();

  const confirmModal = () => {
    const res = window.confirm(`Підтверджуєте відключення телеграм?`);
    toast.promise(res && req({ id: user?._id }), {
      pending: 'Обробка',
      success: data?.msg,
      error: 'Помилка видалення',
    });
  };

  return (
    <>
      <div className="container wide-xl">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-content-wrap">
              <div className="nk-block-head">
                <div className="nk-block-between">
                  <div className="nk-block-area align-items-center g-3">
                    <div className="nk-block-head-content">
                      <Link
                        to="/admin/users"
                        className="btn btn-icon btn-outline-light bg-white d-inline-flex">
                        <em className="icon ni ni-arrow-left"></em>
                      </Link>
                    </div>

                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title">
                        Пацієнт /{' '}
                        <strong className="text-primary small">
                          {user?.firstname} {user?.lastname}
                        </strong>
                      </h3>
                      <div className="nk-block-des text-soft">
                        <ul className="list-inline">
                          <li>
                            ID: <span className="text-base">{user?._id}</span>
                          </li>
                          <li>
                            Дата реєстрації:{' '}
                            <span className="text-base">{moment(user?.date).calendar()}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="nk-block-head-content d-flex" style={{ gap: 20 }}>
                    {user?.telegram ? (
                      <>
                        <span className="badge badge-dot bg-success">
                          Telegram ID: {user?.telegram}
                        </span>
                        {user?.aligner.length > 0 ? (
                          <a
                            className="btn btn-primary"
                            onClick={() =>
                              dispatch(
                                handlerModal({
                                  name: 'VIEW-NOTIFY',
                                  data: { aligner: user?.aligner },
                                }),
                              )
                            }>
                            <span>Переглянути нагадування</span>
                          </a>
                        ) : (
                          <>
                            <a
                              className="btn btn-primary"
                              onClick={() =>
                                dispatch(
                                  handlerModal({
                                    name: 'CREATE-NOTIFY',
                                    data: { id: user?._id, telegramId: user?._id },
                                  }),
                                )
                              }>
                              <em className="icon ni ni-plus" />
                              <span>Добавити нагадування</span>
                            </a>
                            <a className="btn btn-danger" onClick={() => confirmModal()}>
                              <em className="icon ni ni-unlink-alt" />
                            </a>
                          </>
                        )}
                      </>
                    ) : (
                      <a
                        className="btn btn-dim btn-outline-light"
                        onClick={() =>
                          dispatch(
                            handlerModal({ name: 'CONNECT-TELEGRAM', data: { id: user?._id } }),
                          )
                        }>
                        <span>Підключити Telegram</span>
                      </a>
                    )}
                    <a className="btn btn-soft" onClick={() => refetch()}>
                      <em className="icon ni ni-reload-alt" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xl-4">
                    <div className="card card-bordered">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="user-card">
                            <div className="user-info">
                              <h5>{user && getFullName(user)}</h5>
                            </div>
                          </div>
                        </div>

                        <div className="card-inner">
                          <h6 className="overline-title mb-2">Інформація</h6>
                          <div className="row g-3">
                            <div className="col-sm-6 col-xl-12">
                              <span className="sub-text">Стать:</span>
                              <span>{user?.gender}</span>
                            </div>
                            {user?.birthday && (
                              <div className="col-sm-6 col-xl-12">
                                <span className="sub-text">Дата народження:</span>
                                <span>{getDate(user?.birthday).getDateString()}</span>
                              </div>
                            )}
                            {user?.address && (
                              <div className="col-sm-6 col-xl-12">
                                <span className="sub-text">Адрес проживання:</span>
                                <span>{user?.address}</span>
                              </div>
                            )}
                            {user?.phone && (
                              <div className="col-sm-6 col-xl-12">
                                <span className="sub-text">Телефон:</span>
                                <span>{user?.phone}</span>
                              </div>
                            )}
                            {user?.email && (
                              <div className="col-sm-6 col-xl-12">
                                <span className="sub-text">Email:</span>
                                <span>{user?.email}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="nk-block">
                      <div className="row g-3">
                        <div className="col-12">
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h6 className="lead-text">
                                      Інформована згода на попередній огляд та консультацію
                                    </h6>
                                  </div>
                                </div>
                                <ul className="btn-toolbar justify-center gx-1 me-n1 flex-nowrap">
                                  <li>
                                    <Link
                                      to={`${location.pathname}/agreement`}
                                      className="btn btn-trigger btn-icon">
                                      <em className="icon ni ni-eye"></em>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {user?.questionHealth
                      ?.slice()
                      .reverse()
                      .map((e, index) => (
                        <div className="nk-block" key={index}>
                          <h6 className="lead-text mb-3">
                            <span className="badge badge-dim bg-secondary">
                              Дата: {moment(e.date).calendar()}
                            </span>
                          </h6>
                          <div className="row g-3">
                            <div className="col-6">
                              <div className="card card-bordered">
                                <div className="card-inner">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <h6 className="lead-text">Анкета про стан здоров’я</h6>
                                      </div>
                                    </div>
                                    <ul className="btn-toolbar justify-center gx-1 me-n1 flex-nowrap">
                                      <li>
                                        <Link
                                          to={`${location.pathname}/health/${index + 1}`}
                                          className="btn btn-trigger btn-icon">
                                          <em className="icon ni ni-eye"></em>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="card card-bordered">
                                <div className="card-inner">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <h6 className="lead-text">
                                          Aнкета опитувальник для пацієнта
                                        </h6>
                                      </div>
                                    </div>
                                    <ul className="btn-toolbar justify-center gx-1 me-n1 flex-nowrap">
                                      <li>
                                        <Link
                                          to={`${location.pathname}/survey/${index + 1}`}
                                          className="btn btn-trigger btn-icon">
                                          <em className="icon ni ni-eye"></em>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.isVisible && modal?.name === 'CONNECT-TELEGRAM' && <PopupConnectTelegram />}
      {modal.isVisible && modal?.name === 'CREATE-NOTIFY' && <PopupAddNotify />}
      {modal.isVisible && modal?.name === 'VIEW-NOTIFY' && <PopupViewAligner />}
    </>
  );
}

export default UserProfile;
