import {useEffect} from "react";
import SurveyDocumentPage from "./SurveyDocumentPage";

function SurveyPagePrint() {

    useEffect(()=>{
        window.print()
    },)

    return (
        <>
            <SurveyDocumentPage />
        </>
    );
}

export default SurveyPagePrint;
