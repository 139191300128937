import { emptyApi } from './generalAPI';

const notificationApi = emptyApi.enhanceEndpoints({ addTagTypes: ['Notify'] }).injectEndpoints({
  endpoints: (build) => ({
    readNotify: build.mutation({
      query: (body) => ({
        url: `admin/notify`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Notify', id: 'LIST' }],
    }),
    getNotify: build.query({
      query: () => 'admin/notify',
      providesTags: (result) =>
        result
          ? [...result.map(({ _id }) => ({ type: 'Notify', _id })), { type: 'Notify', id: 'LIST' }]
          : [{ type: 'Notify', id: 'LIST' }],
    }),
  }),
});

export const { useGetNotifyQuery, useReadNotifyMutation } = notificationApi;
