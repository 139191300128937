import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handlerModal } from '../redux/siteStore';

function Popup({ children, textCenter }) {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.site);

  useEffect(() => {
    modal.isVisible
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open');
  }, [modal]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [modal]);

  const handleOutsideClick = (e) => {
    if (modal.isVisible && !e.target.closest('#popup-wrapper')) {
      dispatch(handlerModal({ name: modal.name }));
    }
  };

  if (modal.isVisible) {
    return (
      <>
        <div
          className={modal.isVisible ? 'modal fade show' : 'modal fade'}
          style={{ display: modal.isVisible ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content" id="popup-wrapper">
              <div
                className={
                  textCenter ? 'modal-body modal-body-md text-center' : 'modal-body modal-body-md'
                }>
                <div className="nk-modal">{children}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </>
    );
  } else return <></>;
}

export default Popup;
