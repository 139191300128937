import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import Popup from '../../Components/Popup';
import { handlerModal } from '../../redux/siteStore';
import moment from 'moment';
import { Input, InputCheckbox } from '../../Components/input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAddNewPacientMutation } from '../../redux/API/pacients';
import { useAddNewAlignerNotifyMutation } from '../../redux/API/aligner';
import { useNavigate } from 'react-router-dom';

export const PopupConnectTelegram = () => {
  const { modal } = useSelector((state) => state.site);
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    modal?.data && setInputValue(`https://t.me/frankoclinicbot?start=${modal?.data.id}`);
  }, [modal]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inputValue).catch((err) => {
      console.error('Помилка при збереженні тексту в буфері обміну:', err);
    });
  };

  return (
    <Popup>
      <h5 className="modal-title mb-4">Привязати аккаунт Телеграм</h5>

      <div className="nk-modal">
        <div className="nk-modal-text">
          <div className="caption-text">
            Відправте силку клієнту для підключення аккаунта телеграм
          </div>

          <div className="form-group pt-2">
            <div className="form-control-wrap">
              <div className="form-icon form-icon-right pointer" onClick={copyToClipboard}>
                <em className="icon ni ni-copy-fill" />
              </div>
              <input
                type="text"
                className="form-control"
                id="default-04"
                value={inputValue}
                disabled
                onClick={(e) => e.currentTarget.select()}
              />
            </div>
          </div>
        </div>
        <div className="nk-modal-action">
          <a
            className="btn btn-lg btn-mw btn-primary"
            onClick={() => dispatch(handlerModal({ name: 'CONNECT-TELEGRAM', isVisible: false }))}>
            OK
          </a>
        </div>
      </div>
    </Popup>
  );
};

const MyForm = () => {
  const methods = useForm();
  const { control, register, handleSubmit } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'list',
  });
  const { modal } = useSelector((state) => state.site);
  const [periods, setPeriods] = useState(10);
  const [req, { isSuccess, isLoading }] = useAddNewAlignerNotifyMutation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data) => {
    req(data);
  };

  useEffect(() => {
    if (isLoading || isSuccess) {
      setDisabled(true);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(handlerModal());
    }
  }, [isSuccess]);

  return (
    <>
      <div className="row pb-2">
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              Періоди заміни
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control"
                id="default-01"
                defaultValue={periods}
                onChange={(e) => setPeriods(e.target.value)}
                min={0}
                max={30}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="nk-modal-text pt-2">
        <p className="lead">Список:</p>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" value={modal?.data.telegramId} {...register('telegramId')} />

          {fields.map((item, index) => (
            <div className="row align-items-center pb-2" key={item.id}>
              <div className="col-4">Нагадування №{index + 1}</div>
              <div className="col-7">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <input
                      type="date"
                      className="form-control"
                      id={`list-date-${index}`}
                      {...register(`list[${index}].date`)}
                      defaultValue={moment()
                        .add(periods * (index + 1), 'day')
                        .format('yyyy-MM-DD')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-1">
                <em
                  className="icon ni ni-trash-fill delete-notify"
                  onClick={() => remove(index)}></em>
              </div>
            </div>
          ))}

          <a
            onClick={() => append()}
            className="btn btn-outline-primary pointer w-100 d-flex justify-content-center">
            Добавити нове нагадування
          </a>

          <hr />

          <div className="nk-modal-action">
            <button type="submit" className="btn btn-lg btn-mw btn-primary" disabled={disabled}>
              {isLoading && <div className="spinner-border spinner-border-sm"></div>}
              {!isLoading && 'Створити нагадування пацієнту'}
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export const PopupAddNotify = () => {
  return (
    <Popup>
      <h5 className="modal-title mb-4">Добавити нагадування пацієнту</h5>
      <MyForm />
    </Popup>
  );
};

export const PopupAddNewPacient = () => {
  const nav = useNavigate();
  const schema = yup
    .object({
      gender: yup.string().required('Виберіть стать'),
      firstname: yup.string().required('Поле не може бути пустим'),
      lastname: yup.string().required('Поле не може бути пустим'),
      surname: yup.string().required('Поле не може бути пустим'),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [req, { isSuccess, isLoading, data }] = useAddNewPacientMutation();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading || isSuccess) {
      setDisabled(true);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(handlerModal());
      nav(`/admin/user/${data?.data.id}`);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    req(data);
  };

  return (
    <Popup>
      <h5 className="modal-title mb-4">Добавити нагадування пацієнту</h5>
      <form className="form-validate" onSubmit={handleSubmit(onSubmit)}>
        <div className="row gy-4">
          <InputCheckbox
            size={12}
            title={'Стать'}
            name={'gender'}
            data={[
              { id: 'man', title: 'Чоловік' },
              { id: 'woman', title: 'Жінка' },
            ]}
            control={control}
            register={register}
            err={errors}
          />
          <Input
            size={4}
            title="Прізвище"
            placeholder="Введіть своє прізвище"
            icon="ni-user"
            name="firstname"
            autocomplete="firstName"
            control={control}
            register={register}
            required={true}
            err={errors}
          />
          <Input
            size={4}
            title="Ім'я"
            placeholder="Введіть своє ім'я"
            icon="ni-user"
            name="lastname"
            autocomplete="lastName"
            control={control}
            register={register}
            required={true}
            err={errors}
          />
          <Input
            size={4}
            title="По-батькові"
            placeholder="Введіть своє ім'я по-батькові"
            icon="ni-user"
            name="surname"
            control={control}
            register={register}
            required={true}
            err={errors}
          />
        </div>

        <div className="nk-modal-action">
          <button type="submit" className="btn btn-lg btn-mw btn-primary" disabled={disabled}>
            {isLoading && <div className="spinner-border spinner-border-sm"></div>}
            {!isLoading && 'Створити нагадування пацієнту'}
          </button>
        </div>
      </form>
    </Popup>
  );
};
export const PopupViewAligner = () => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.site);

  return (
    <Popup>
      <h5 className="modal-title mb-4">Добавити нагадування пацієнту</h5>
      <div className="nk-block card card-bordered">
        <table className="table table-ulogs">
          <thead className="table-light">
            <tr>
              <th className="tb-col-os">
                <span className="overline-title">#</span>
              </th>
              <th className="tb-col-ip">
                <span className="overline-title">Дата</span>
              </th>
              <th className="tb-col-time">
                <span className="overline-title">Статус</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {modal?.data?.aligner[0].aligner.map((e) => {
              return (
                <tr key={e._id.slice(-1)}>
                  <td className="tb-col-os">Нагадування {e.name}</td>
                  <td className="tb-col-ip">
                    <span className="sub-text">
                      {moment(new Date(e.date)).format('DD/MM/YYYY')}
                    </span>
                  </td>
                  <td className="tb-col-time">
                    <span className="sub-text">
                      {e.notify ? (
                        <span className="badge rounded-pill bg-success">Відправлено</span>
                      ) : (
                        <span className="badge rounded-pill bg-outline-gray">Очікує відправки</span>
                      )}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Popup>
  );
};
