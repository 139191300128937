import {Outlet} from "react-router-dom";
import Header from "../Header";


function AdminOutlet() {

    return (
        <>
            <div className="nk-main ">
                <div className="nk-wrap ">
                    <Header/>
                    <div className="nk-content ">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminOutlet;
